body {
  margin: 0;
  font-family: sans-serif;
}
main.weather-main {
  background-color: #005986;
  padding-bottom: 50px;
}
header {
  height: 82px;
  background-color: #005986;
  width: 100%;
  color: #ffffff;
}
.header-container {
  display: flex;
  justify-content: space-between;
  padding: 11px 11px 11px 6px;
  margin: 0 auto;
  align-items: center;
  box-sizing: border-box;
  flex: 1 0 auto;
  height: 82px;
  max-width: 1280px;
}
nav {
  background: #0006;
  height: 40px;
  color: #ffffff;
  display: flex;
}
nav a {
  cursor: pointer;
  display: flex;
}
.nav-container {
  display: flex;
  max-width: 1280px;
  margin: auto;
  justify-content: space-around;
  width: 100%;
}
.nav-container svg{
  width: 17px;
  margin: 0 5px;
}
.search {
  position: relative;
}
.search input{
  background: #fff3;
  border: 0;
  border-radius: 20px;
  padding: 5px 6px;
  text-align: center;
  width: 380px;
  height: 29px;
  color: #ffffff;
  font-size: 1rem!important;
  box-sizing: border-box;
}
.search input::placeholder{
  color: #ffffff;
}
.menu {
  width: 30px;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  margin-left: 25px;
}
.menu span {
  background: #fff;
  border-radius: 2px;
  display: block;
  height: 3px;
  margin: 5px 0;
}
.logo {
  display: flex;
  align-items: center;
}
.logo img {
  padding: 0 4px 0 12px;
}
.logo svg {
  height: 60px!important;
  width: 60px!important;
}
.header-widget {
  color: #fff;
  height: 40px;
  background: #fff3;
}
.header-widget p {
  margin: 0;
  padding: 10px 0;
  width: 25%;
  border-right: 1px solid #fff3;
}
.weather-container {
  max-width: 1162px;
  margin: 12px auto;
  display: flex;
}
.block {
  border-radius: 6px;
  box-shadow: 0 0 12px 0 #0003;
  margin-bottom: 12px;
  overflow: hidden;
}
.widget-block {
  background: #ffffff;
  padding: 16px;
}
.widget-block img {
  margin: auto;
  display: block;
}
.weather-container > div:first-child{
  width: 788px;
  margin-right: 12px;
}
.weather {
  height: 272px;
}
.chart {
  height: 245px;
}
.btn {
  box-shadow: 0 0 5px 0 #1b4de4cc;
  background-color: #1b4de4;
  border: 1px solid #1b4de4;
  border-radius: 16px;
  cursor: pointer;
  margin: 5px;
  padding: 8px 14px;
  color: #ffffff;
  display: inline-block;
  font-size: .8125rem;
  font-weight: 700;
  line-height: 1.15;
  outline: none;
  text-decoration: none;
  white-space: nowrap;
}
.slider {
  display: flex;
}
.slider > div {
  width: 25%;
}
.menuButton {
  background: none;
  border: none;
  cursor: pointer;
  color: #ffffff;
}
.menuButtonInner {
  align-items: center;
  color: #fff;
  display: flex;
  flex-shrink: 0;
  font-size: .875rem;
}
.globeIcon {
  width: 24px;
  padding: 0 0 0 10px;
}
.lightTheme {
  fill: #fff;
  --color-moon: #fff;
  --color-cloud: #fff;
  --color-star: #fff;
  --color-fog: #fff;
  --color-wind: #fff;
  --color-sun: #fff;
  --color-lightning: #fff;
  --color-snowflake: #fff;
  --color-hail: #fff;
  --color-thunderstorm-mask: #2b2b2b;
  color: #fff;
}
.menuButtonInner span {
  padding: 0 10px;
}
.unitDisplay {
  border-left: 1px solid #fff;
}

.input {
  font-size: 20px;
}