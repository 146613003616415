.liberty-widget button.secondary {
    border-radius: 6px;
    width: 100%;
}
.liberty-widget .ix-form-div input, 
.liberty-widget .ix-form-div select {
    border-radius: 4px !important;
}

#main-white-liberty-widget .ix-heroImage img,
#main-yellow-liberty-widget .ix-heroImage img{
    position: initial;
    transform: none;
}

#ix-yellow-liberty-widget .ix-heroImage {
    background: #ffffff;
}

#main-white-liberty-widget .vertical-layout-item:first-child,
#main-white-liberty-widget .vertical-layout-item:nth-child(2),
#main-white-liberty-widget .vertical-layout-item:nth-child(6)  {
    width: calc(50% - 12px);
}
#main-white-liberty-widget .vertical-layout-item:nth-child(3) {
    width: calc(100% - 12px);
}
#main-white-liberty-widget .vertical-layout-item:nth-child(4),
#main-white-liberty-widget .vertical-layout-item:nth-child(5) {
    width: calc(25% - 12px);
}
#main-white-liberty-widget .vertical-layout-item{
    float: left;
    margin: 0 6px 5px;
}
#main-white-liberty-widget .ix-content .vertical-layout-item {
  width: auto;
  float: none;
}